<div class="container-fluid">
  <form [formGroup]="loading_kind_form">
    <div class="row">
      <div class="col-12 mt-2">
        <div class="m-2">
          <ion-button color="secondary" class="full-width" (click)="goBack()">
            <ion-icon name="arrow-back-outline"></ion-icon>
            <ion-label class="ms-2" translate>go_back_data</ion-label>
          </ion-button>
        </div>
      </div>

      <div class="col-sm-12 col-md-4 col-xl-4 col-xxl m-2">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label translate>loading_kind</mat-label>
          <mat-select (selectionChange)="loadingKindChanged($event)" formControlName="loading_kind">
            <mat-option *ngFor="let kind of loading_kinds" [value]="kind">{{kind | translate}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-sm-12 col-md-4 col-xl-4 col-xxl m-2">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label translate>unloading_kind</mat-label>
          <mat-select (selectionChange)="unloadingKindChanged($event)" formControlName="unloading_kind">
            <mat-option *ngFor="let kind of unloading_kinds" [value]="kind">{{kind | translate}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-sm-12 col-md-4 col-xl-4 col-xxl m-2">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label translate>car_type</mat-label>
          <mat-select formControlName="car">
            <mat-option *ngFor="let car of cars" [value]="car">{{car | translate}}</mat-option>
          </mat-select>
        </mat-form-field>

      </div>
      <div class="alert alert-warning m-2" role="alert" *ngIf="showOverSizeAlert">
        <p class="alert-heading" [innerHTML]="'oversize_title' | translate"></p>
        <h4 translate>oversize_message</h4>
      </div>
    </div>
    <hr>
    <div class="container-fluid gx-0">
      <div class="row overflow-hidden">
        <div class="col-xl-3 col-lg-4 col-md-6 col-12 mb-3" *ngIf="showClassic">
          <ion-card>
            <ion-card-header>
              <ion-card-title>
                <span translate [innerHTML]="'shipment_method_standard' | translate"></span>

                <hr />
              </ion-card-title>
            </ion-card-header>
            <ion-card-content>
              <ion-list>
                <ion-item lines="none" *ngFor="let item of ('standard_plan' | translate) | comparisationFormatter">
                  <ion-icon name="checkmark-outline"></ion-icon>
                  <ion-label class="ms-2" text-wrap>
                    <small>{{ item }}</small>
                  </ion-label>
                </ion-item>
                <ion-item lines="none">
                  <ion-label class="ms-2" text-wrap>
                    <small> <b>{{ 'pickup_from' | translate}}:
                      </b>{{this._date.getStartDateForPlan1(this.findFirstAvailableDate(0)!) | date:
                      'dd.MM.yyyy'}}</small>
                  </ion-label>
                </ion-item>
                <ion-item lines="none">

                  <ion-label class="ms-2" text-wrap>
                    <small> <b>{{ 'deliver_from_text' | translate}}:
                      </b>{{this._date.getStartDateForPlan1(this.findFirstAvailableDate(0)!) | date:
                      'dd.MM'}} - {{this._date.getEndDateForPlan1Date(this.findFirstAvailableDate(0)!) | date:
                      'dd.MM.yyyy'}}</small>
                  </ion-label>
                </ion-item>
              </ion-list>
            </ion-card-content>

            <div class="card-footer">
              <div class="m-3">
                <hr />

                <b class="price" translate>price</b>
                <h3 class="price">
                  <span>
                    {{ planPriceData[0] ? planPriceData[0] + ' &euro;' : '-' }}
                  </span>

                </h3>
                <small translate>additional_tax</small>
              </div>

              <ion-button color="secondary" style="margin: 0px" class="full-width" (click)="selectPlan(0)"
                [disabled]="!plan1Possible || !this.loading_kind_form.valid" translate>choose</ion-button>
            </div>
          </ion-card>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-12 mb-3" *ngIf="showPoint">
          <ion-card>
            <ion-card-header>
              <ion-card-title>
                <span [innerHTML]="'shipment_method_priority' | translate"></span>
                <hr />
              </ion-card-title>
            </ion-card-header>
            <ion-card-content>
              <ion-list>
                <ion-item lines="none" *ngFor="let item of ('express_plan' | translate) | comparisationFormatter">
                  <ion-icon name="checkmark-outline"></ion-icon>
                  <ion-label class="ms-2" text-wrap>
                    <small>{{ item }}</small>
                  </ion-label>
                </ion-item>
                <ion-item lines="none">

                  <ion-label class="ms-2" text-wrap>
                    <small><b>{{ 'pickup_from' | translate}}:</b>
                      {{this.plan2StartDate?.split('-')[0]?.trim()}}{{this.plan2StartDate[this.plan2StartDate.length-4]}}{{this.plan2StartDate[this.plan2StartDate.length-3]}}{{this.plan2StartDate[this.plan2StartDate.length-2]}}{{this.plan2StartDate[this.plan2StartDate.length-1]}}</small>
                  </ion-label>
                </ion-item>
                <ion-item lines="none">

                  <ion-label class="ms-2" text-wrap>
                    <small> <b>{{ 'deliver_from_text' | translate}}: </b>{{this.plan2StartDate}}</small>
                  </ion-label>
                </ion-item>
              </ion-list>
            </ion-card-content>

            <div class="card-footer">
              <div class="m-3">
                <hr />

                <b class="price" translate>price</b>
                <h3 class="price">

                  <span>
                    {{ planPriceData[1] ? planPriceData[1]+ ' &euro;' : '-' }}
                  </span>

                </h3>
                <small translate>additional_tax</small>
              </div>

              <ion-button color="secondary" style="margin: 0px" class="full-width" (click)="selectPlan(1)"
                [disabled]="!plan2Possible || !this.loading_kind_form.valid " translate>choose</ion-button>
            </div>
          </ion-card>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-12 mb-3" *ngIf="showDynamic">
          <ion-card>
            <ion-card-header>
              <ion-card-title>
                <span [innerHTML]="'shipment_method_exact_delivery_date' | translate"></span>

                <hr />
              </ion-card-title>
            </ion-card-header>
            <ion-card-content>
              <ion-list>
                <ion-item lines="none"
                  *ngFor="let item of ('exact_delivery_plan' | translate) | comparisationFormatter">
                  <ion-icon name="checkmark-outline"></ion-icon>
                  <ion-label class="ms-2" text-wrap>
                    <small>{{ item }}</small>
                  </ion-label>
                </ion-item>
                <ion-item lines="none">

                  <ion-label class="ms-2" text-wrap>
                    <small><b>{{ 'pickup_from' | translate}}:</b>
                      {{plan3Range.split('-')[0].trim()}}{{currentYear}}</small>
                  </ion-label>
                </ion-item>

                <ion-item lines="none">
                  <ion-label class="ms-2" text-wrap *ngIf="plan3Range.split('-').length==2">
                    <small> <b>{{ 'deliver_from_text' | translate}}:
                      </b> {{plan3Range.split('-')[1].trim()}}</small>
                  </ion-label>
                </ion-item>
              </ion-list>
            </ion-card-content>

            <div class="card-footer">
              <div class="m-3">
                <hr />

                <b class="price" translate>price</b>
                <h3 class="price">

                  <span>
                    {{ planPriceData[2] ? planPriceData[2]+ ' &euro;' : '-' }}
                  </span>

                </h3>
                <small translate>additional_tax</small>
              </div>

              <ion-button color="secondary" style="margin: 0px" class="full-width" (click)="selectPlan(2)"
                [disabled]="!plan3Possible || !this.loading_kind_form.valid" translate>choose</ion-button>
            </div>
          </ion-card>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-12 mb-3" *ngIf="showHubflex">
          <ion-card>
            <ion-card-header>
              <ion-card-title>
                <span [innerHTML]="'shipment_method_direct_drive' | translate"></span>

                <hr />
              </ion-card-title>
            </ion-card-header>
            <ion-card-content>
              <ion-list>
                <ion-item lines="none" *ngFor="let item of ('direct_drive_plan' | translate) | comparisationFormatter">
                  <ion-icon name="checkmark-outline"></ion-icon>
                  <ion-label class="ms-2" text-wrap>
                    <small>{{ item }}</small>
                  </ion-label>
                </ion-item>
                <ion-item lines="none">

                  <ion-label class="ms-2" text-wrap>
                    <small> <b>{{ 'pickup_from' | translate}}:
                      </b>{{this._date.getStartDateForPlan1(this.findFirstAvailableDate(0)!) | date:
                      'dd.MM.yyyy'}}</small>
                  </ion-label>
                </ion-item>
                <ion-item lines="none">

                  <ion-label class="ms-2" text-wrap>
                    <small> <b>{{ 'deliver_from_text' | translate}}:
                      </b>{{this._date.getStartDateForPlan1(this.findFirstAvailableDate(0)!) | date:
                      'dd.MM'}} - {{this._date.getEndDateForPlan1Date(this.findFirstAvailableDate(0)!) | date:
                      'dd.MM.yyyy'}}</small>
                  </ion-label>
                </ion-item>
              </ion-list>
            </ion-card-content>

            <div class="card-footer">
              <div class="m-3">
                <hr />

                <b class="price" translate>price</b>
                <h3 class="price">
                  <span>
                    {{ planPriceData[3] ? planPriceData[3] + ' &euro;' : '-' }}
                  </span>

                </h3>
                <small translate>additional_tax</small>
              </div>

              <ion-button color="secondary" style="margin: 0px" class="full-width" (click)="selectPlan(3)"
                [disabled]="!plan4Possible || !this.loading_kind_form.valid " translate>choose</ion-button>
            </div>
          </ion-card>
        </div>
      </div>
    </div>
  </form>
</div>