import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-request-successful-delivery-docs',
  templateUrl: './request-successful-delivery-docs.component.html',
  styleUrls: ['./request-successful-delivery-docs.component.scss'],
})
export class RequestSuccessfulDeliveryDocsComponent implements OnInit {

  constructor(private _modal: ModalController) { }

  ngOnInit() {}

  dismiss() {
    this._modal.dismiss();
  }
}
