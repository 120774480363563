<ion-content #contentContainer>
  <div class="container-fluid gx-0" *ngIf="!offerCreated">
    <div class="inner mt-4 ms-auto me-auto" id="inner" #inner>
      <ion-card class="firstInputCard">
        <div class="clearfix">
          <div class="float-start">
            <img src="assets/logo/logo_vehar_schriftzug.png" (click)="reloadPage()" class="login-logo pt-4 ps-2">
          </div>
          <div class="float-end pe-3 pt-3 text-end">
            <ion-buttons>
              <ion-button shape="circle" (click)="setLang('de')" class="lang-button me-2"
                [class.selected]="_translate.currentLang == 'de'">
                <img src="assets/countries/de.svg" height="30" width="30" />
              </ion-button>
              <ion-button shape="circle" (click)="setLang('en')" class="lang-button"
                [class.selected]="_translate.currentLang == 'en'">
                <img src="assets/countries/en.svg" height="30" width="30" />
              </ion-button>
            </ion-buttons>
          </div>

        </div>

        <form [formGroup]="getFormGroup('address_preselect')">
          <div class="row m-2 mt-3 pt-3">

            <div class="col-lg-5 col-12 ">
              <mat-form-field appearance="outline" class="full-width" color="primary">
                <mat-label translate>pickup_placeholder</mat-label>
                <input formControlName="from_address" (focus)="cities = []" [matAutocomplete]="auto" matInput
                  required />
                <mat-icon matSuffix (click)="autofillCity('from_address')">my_location</mat-icon>
              </mat-form-field>
            </div>
            <div class="col-lg-2 col-12 text-center mb-3 mt-md-2">
              <ion-button class="swap-button" color="secondary" (click)="flipAddress()">
                <ion-icon name="swap-horizontal-outline"></ion-icon>
              </ion-button>
            </div>
            <div class="col-lg-5 col-12">
              <mat-form-field appearance="outline" class="full-width">
                <mat-label translate>delivery_placeholder</mat-label>
                <input formControlName="to_address" (focus)="cities = []" [matAutocomplete]="auto" matInput required />
                <mat-icon matSuffix (click)="autofillCity('to_address')">my_location</mat-icon>
              </mat-form-field>
            </div>
            <mat-autocomplete (optionSelected)="checkIfCityInGermany($event)" #auto="matAutocomplete"
              [displayWith]="getCityName">
              <mat-option *ngFor="let city of cities" [value]="city">
                <img src="assets/countries/{{city.country_code?.toLowerCase()}}.svg" class="me-2" width="20" />
                {{city.zipcode}} {{city.city}} <small>{{city.country_code}}</small>
              </mat-option>
            </mat-autocomplete>
          </div>
        </form>

        <ion-card-content>
          <mat-tab-group [selectedIndex]="activeTab" dynamicHeight #pickupType (selectedTabChange)="changeTab($event)">
            <mat-tab>
              <ng-template mat-tab-label>
                <span [matTooltip]="'tab1_tooltip'|translate" translate class="label">palet_box_tab</span>
              </ng-template>
              <div class="crate">
                <app-crate-input [isDirect]="true" [individual]="false" [crateExists]="crateExists" [isIframe]="true"
                  [group]="this.getFormGroup('crates')"></app-crate-input>
              </div>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>
                <span [matTooltip]="'tab2_tooltip'|translate" translate class="label">loading_meter_tab</span>
              </ng-template>

              <app-loading-meter-input [isIframe]="true"
                [group]="this.getFormGroup('loading_meter')"></app-loading-meter-input>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>
                <span [matTooltip]="'tab2_tooltip'|translate" translate class="label">enter_vehicle_type</span>
              </ng-template>

              <app-vehicle-input [isIframe]="true"></app-vehicle-input>
            </mat-tab>
          </mat-tab-group>
        </ion-card-content>
        <div align="center">
          <ion-button color="secondary"
            [disabled]="this.getFormGroup('address_preselect').invalid || (pickupType.selectedIndex == 0 ? this.getFormGroup('crates').invalid : pickupType.selectedIndex == 1 ? this.getFormGroup('loading_meter').invalid : false)"
            class=" submit-button mt-2 calculate" (click)="calculateShipping()" translate>calculate_price</ion-button>
        </div>
        <div align="center" class="mt-3">
          <small class="label">Powered by Vehar<sup>&reg;</sup></small>
        </div>
        <div align="center" class="mt-3 mb-4">
          <p class="label">Unsere Dienstleistungen richten sich ausschließlich an Gewerbekunden, Behörden und
            Unternehmen.
          </p>
        </div>
      </ion-card>
    </div>
  </div>

  <div class="container-fluid gx-0 mt-2" *ngIf="offerCreated">
    <ion-card class="firstInputCard">
      <app-crate-compare (onBackClicked)="goBack()" [group]="this.group" [isDirect]="true"
        [planPriceData]="planPriceData" [selectedPickupType]="this.group.get('selectedTab')?.value ?? 0"
        [stepper]="null" *ngIf="this.group.get('selectedTab')?.value != 2"
        (planSelected)="updatePlan($event)"></app-crate-compare>

      <app-vehicle-compare [stepper]="null" [group]="this.group" (onBackClicked)="goBack()"
        [vehiclePriceData]="this.planPriceData" *ngIf="this.group.get('selectedTab')?.value == 2"
        (vehicleSelected)="updatePlan($event)"></app-vehicle-compare>
      <ion-card class="me-4 ms-4">
        <ion-card-header>
          <ion-card-title>
            <span translate><span translate>your_shipment</span></span>
            <span style="float: right" translate><span translate>
                <ion-button (click)="goBack()" class="full-width" color="light" style="margin-top: -10px;">
                  <ion-icon slot="start" name="pencil-outline"></ion-icon>
                  <ion-label class="d-none d-lg-block" translate>edit_shipment</ion-label>
                </ion-button>
              </span>
            </span>
            <br>
            <hr />
          </ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <div class="from-to-container">
            <div class="row">
              <div class="col-md-4 col-12">
                <b style="color: black" translate>from</b><br>
                <img *ngIf="this.getFormGroup('address_preselect').get('from_address')?.value?.country_code"
                  src="../../../../../assets/countries/{{this.getFormGroup('address_preselect').get('from_address')?.value.country_code.toLowerCase()}}.svg"
                  class="country-icon">
                <span class="city">{{getFromAddress()}}</span>
              </div>
              <div class="col-md-4 col-12">
                <b style="color: black" translate>to</b><br>
                <img *ngIf="this.getFormGroup('address_preselect').get('to_address')?.value?.country_code"
                  src="../../../../../assets/countries/{{this.getFormGroup('address_preselect').get('to_address')?.value.country_code.toLowerCase()}}.svg"
                  class="country-icon">
                <span class="city">{{getToAddress()}}</span>
              </div>
              <div class="col-md-4 col-12" *ngIf="getLDM()">
                <b style="color: black" *ngIf="this.group.get('selectedTab')?.value != 2" translate>content </b>
                <br>
                <span style="color: black" *ngIf="this.group.get('selectedTab')?.value != 2">
                  {{getLDM()}} - {{getKG()}}
                </span>
              </div>
            </div>
          </div>
        </ion-card-content>
      </ion-card>
    </ion-card>
  </div>
</ion-content>

<app-loading-overlay *ngIf="showLoader" class="main-loader"></app-loading-overlay>