<div class="inner-content">
  <ion-header class="ion-no-border">
    <ion-toolbar>
      <ion-buttons mode="ios" s slot="end">
        <ion-button mode="ios" class="ms-2" (click)="dismiss()" translate>
          <ion-icon name="close-circle-outline"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>

  <div class="container overflow-hidden ps-4 pe-4 pb-4">
    <div class="row">
      <div class="col-12" align="center">
        <h2>Unique Marketplaces by <span>Vehar<sup>&reg;</sup></span></h2>
      </div>
      <div class="col-12 mt-3 display-flex">
        <ion-icon class="check-icon" color="primary" name="checkmark-circle-outline"></ion-icon>
        <div class="success-text" translate>request_delivery_docs_success</div>
      </div>
    </div>
  </div>
</div>