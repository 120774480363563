import { Component, Inject, Input, OnInit, } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ComplaintsModalComponent } from './complaints-modal/complaints-modal.component';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { CheckoutCompletionModalComponent } from '../checkout-completion-modal/checkout-completion-modal.component';
import { RequestSuccessfulDeliveryDocsComponent } from '../request-successful-delivery-docs/request-successful-delivery-docs.component';
import { DialogDirectUploadFileComponent } from '../dialog-direct-upload-file/dialog-direct-upload-file.component';
import { firstValueFrom } from 'rxjs';
import dayjs from 'dayjs';

@Component({
  selector: 'app-table-detail-bottomsheet',
  templateUrl: './table-detail-bottomsheet.component.html',
  styleUrls: ['./table-detail-bottomsheet.component.scss'],
})
export class TableDetailBottomsheetComponent implements OnInit {



  mail: string | undefined;
  isRequestingDeliveryDocs = false;
  dayjs = dayjs;

  @Input() data: any;

  constructor(
    private router: Router, private _modal: ModalController, private _translate: TranslateService,
    private dialog: MatDialog,
    private _alert: AlertController,
    private _loading: LoadingController, private _http: HttpClient) { }

  ngOnInit() {
    console.log(this.data);
    
    this.getMail();
  }
  getNetPrice(price: any,additionalCosts: any) {
    const totalNet = Number(price) + Number(additionalCosts);
    return this.formatPrice(totalNet);
   }
  formatPrice(price: any){
    return Number(price).toFixed(2).replace('.', ',') + ' €';
  }
  close() {
    this._modal.dismiss(null, undefined, 'table-filtering')
  }

  async openComplaints() {
    const modal = await this._modal.create({
      component: ComplaintsModalComponent,
      cssClass: 'max-width',

      componentProps: { order: this.data }
    });
    await modal.present();
  }

  editOffer() {
    if (this.data.kind == 'Vehar® Anonymous') {
      this.router.navigate([`/dashboard/order/anonymous/${this.data._id}`]);
    } else if (this.data.kind == 'Vehar® Direct') {
      this.router.navigate([`/dashboard/order/direct/${this.data._id}`]);
    } else {
      this.router.navigate([`/dashboard/order/auction/${this.data._id}`]);
    }
  }

  async reloadAnonymousOrder() {
    const text = await this._translate.get('loading_controller_text').toPromise();
    const loading = await this._loading.create({
      message: text,
    });
    loading.present();
    setTimeout(() => {
      this._http.get<any>(`${environment.api_url}/anonymous/${this.data._id}`).subscribe(result => {
        this.data = result;
        this.data.price = this.data.price
        loading.dismiss();
      })
    }, 300)
  }

  getMail() {
    this._http.get<any>(`${environment.api_url}/user`).subscribe(result => {
      if (result?.favourite) {
        if (result.favourite.mail && result.favourite.mail !== 'no_pref') {
          this.mail = result.favourite.mail;
        } else {
          this.mail = 'info@vehar.de';
        }
      } else {
        this.mail = 'info@vehar.de';
      }
    })
  }
  getOfferNr() {
    if (this.data.task_nr) {
      return `Auftrags-Nr: ${this.data.task_nr} | ${this.data.product}`

    } else {
      return `Angebots-Nr: ${this.data.offer_number} | ${this.data.product}`
    }
  }
  payNow() {
    const dialog = this.dialog.open(
      CheckoutCompletionModalComponent,
      {
        data: this.data
      }
    )
    dialog.afterClosed().subscribe(async (result: any) => {
      if (result.data) {
        this.reloadData();
      }
    })
  }
  async reloadDirectOrder() {
    const text = await this._translate.get('loading_controller_text').toPromise();
    const loading = await this._loading.create({
      message: text,
    });
    loading.present();
    setTimeout(() => {
      this._http.get<any>(`${environment.api_url}/direct/${this.data._id}`).subscribe(result => {
        this.data = result;
        loading.dismiss();
      })
    }, 300)
  }

  async reloadAuctionOrder() {
    const text = await this._translate.get('loading_controller_text').toPromise();
    const loading = await this._loading.create({
      message: text,
    });
    loading.present();
    setTimeout(() => {
      this._http.get<any>(`${environment.api_url}/auction/${this.data._id}`).subscribe(result => {
        this.data = result;
        loading.dismiss();
      })
    }, 300)
  }

  async requestDeliveryDocuments() {
    this.close();
    const alert = await this._alert.create({
      message: this._translate.instant('request_delivery_docs_alert'),
      header: this._translate.instant('delivery_documents'),
      buttons: [
        {
          text: this._translate.instant('cancel'),
          role: 'cancel'
        },
        {
          text: this._translate.instant('confirm'),
          role: 'confirm'
        }
      ]
    });

    await alert.present();

    const result = await alert.onDidDismiss();
    if (result.role === 'cancel') {
      return;
    }

    this.isRequestingDeliveryDocs = true;
    try {
      await this._http.post<any>(`${environment.api_url}/direct/delivery/docs`, { orderId: this.data._id }).toPromise();
    } catch (error) {

    }
    this.reloadDirectOrder();

    const modal = await this._modal.create({
      component: RequestSuccessfulDeliveryDocsComponent,
      cssClass: 'auto-height',
      mode: 'ios'
    });
    await modal.present();
  }

  async uploadDocuments() {
    const modal = await this._modal.create({
      component: DialogDirectUploadFileComponent,
      focusTrap: false,
      cssClass: 'auto-height',
    });
    await modal.present();

    const fileResult = await modal.onDidDismiss();
    const loading = await this._loading.create({
      message: 'Uploading file...'
    });

    console.log(this.data);

    await loading.present();
    try {
      const result = await firstValueFrom(this._http.post<any>(`${environment.api_url}/orders/direct/${this.data._id}/attach`, { file: fileResult.data.file }));
      this.reloadData();
    } catch (error) {

    } finally {
      loading.dismiss();
    }
  }

  reloadData() {
    if (this.data.convertedToDirect) {
      this.reloadDirectOrder();
    } else if (this.data.offer_number.startsWith('auction') && !this.data.convertedToDirect) {
      this.reloadAuctionOrder();
    } else {
      this.reloadAnonymousOrder();
    }
  }
}
