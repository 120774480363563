import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact-card',
  templateUrl: './contact-card.component.html',
  styleUrls: ['./contact-card.component.scss'],
})
export class ContactCardComponent implements OnInit {

  constructor() { }

  ngOnInit() { }

  openMail() {
    window.open('mailto:support@vehar.de?subject=Anfrage für Vielversender&body=Sehr geehrte Damen und Herren,%0D%0A%0D%0Aich interessiere mich für Vielversender und bitte um Kontaktaufnahme.%0D%0A%0D%0AMit freundlichen Grüßen%0D%0A%0D%0A%0D%0A%0D%0A', '_blank');
  }

}
