import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-country-not-allowed',
  templateUrl: './country-not-allowed.component.html',
  styleUrls: ['./country-not-allowed.component.scss'],
})
export class CountryNotAllowedComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
