<div class="inner-conent">
  <mat-stepper [linear]="false" #stepper>
    <mat-step>
      <ng-template matStepLabel>
        <span translate>upload</span>
      </ng-template>

      <div class="m-5">
        <app-drop-zone-file-upload [generatedUrl]="false" [acceptFilter]="'application/pdf'" class=""
          title="order_confirmation" (didUploadFileUrl)="storeFilePath($event)"></app-drop-zone-file-upload>

        <button mat-raised-button color="primary" class="w-100" (click)="processFile()" [disabled]="!filePath">
          <span translate>next</span>
        </button>
      </div>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>
        <span translate>proccess</span>
      </ng-template>

      <div class="m-5">
        <mat-spinner *ngIf="showLoader" diameter="100" class="m-auto"></mat-spinner>
      </div>
    </mat-step>
  </mat-stepper>
</div>