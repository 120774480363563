import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { NavigationComponent } from './components/navigation/navigation.component';
import { LoadingOverlayComponent } from './components/loading-overlay/loading-overlay.component';
import { ComparisationFormatterPipe } from './pipes/comparisation-formatter.pipe';
import { MatSortModule } from '@angular/material/sort';
import { NgChartsModule } from 'ng2-charts';
import { TableDetailBottomsheetComponent } from './components/table-detail-bottomsheet/table-detail-bottomsheet.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { SwiperModule } from 'swiper/angular';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { CheckoutCompletionModalComponent } from './components/checkout-completion-modal/checkout-completion-modal.component';
import { CheckoutAuctionAnonymousCompletionModalComponent } from './components/checkout-auction-anonymous-completion-modal/checkout-auction-anonymous-completion-modal.component';
import { NgxMaskModule } from 'ngx-mask';
import { FooterComponent } from './components/footer/footer.component';
import { CountUpDirective } from './directives/count-up/count-up.directive';
import { InsuranceResultModalComponent } from './pages/dashboard/order/direct/insurance-result-modal/insurance-result-modal.component';
import { DropZoneFileUploadComponent } from './components/drop-zone-file-upload/drop-zone-file-upload.component';
import { DndDirective } from './components/drop-zone-file-upload/directive/dnd.directive';
import { AnonymousAuctionContentComponent } from './components/table-detail-bottomsheet/anonymous-auction-content/anonymous-auction-content.component';
import { DirectContentComponent } from './components/table-detail-bottomsheet/direct-content/direct-content.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TableFilteringComponent } from './components/table-filtering/table-filtering.component';
import { NgxGdprCookieConsentModule } from 'ngx-gdpr-cookie-consent';
import { SurveyComponent } from './components/table-detail-bottomsheet/complaints-modal/survey/survey.component';
import { MatStepperModule } from '@angular/material/stepper';
import { DetailsComponent } from './components/table-detail-bottomsheet/complaints-modal/details/details.component';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';

import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { FirstViewComponent } from './components/table-detail-bottomsheet/complaints-modal/first-view/first-view.component';
import { EmailFormComponent } from './components/table-detail-bottomsheet/complaints-modal/email-form/email-form.component';
import { SavedComplaintComponent } from './components/table-detail-bottomsheet/saved-complaint/saved-complaint.component';
import { ComplaintsModalComponent } from './components/table-detail-bottomsheet/complaints-modal/complaints-modal.component';
import { UploadFilesComponent } from './components/table-detail-bottomsheet/complaints-modal/upload-files/upload-files.component';
import { AuthService } from './services/auth/auth.service';
import { AcceptLegalNoticeComponent } from './components/table-detail-bottomsheet/complaints-modal/accept-legal-notice/accept-legal-notice.component';
import { StatusComponent } from './components/charts/status/status.component';
import { SalesComponent } from './components/charts/sales/sales.component';
import { SupplierAuctionAnonymousDetailComponent } from './components/supplier-auction-anonymous-detail/supplier-auction-anonymous-detail.component';
import { AnonymousAuctionSupplierContentComponent } from './components/supplier-auction-anonymous-detail/anonymous-auction-supplier-content/anonymous-auction-supplier-content.component';
import { BetTooLowDialogComponent } from './components/auction/bet-too-low-dialog/bet-too-low-dialog.component';
import { BetSuccessDialogComponent } from './components/auction/bet-success-dialog/bet-success-dialog.component';
import { BetDeleteDialogComponent } from './components/auction/bet-delete-dialog/bet-delete-dialog.component';
import { BetBuyNowComponent } from './components/auction/bet-buy-now/bet-buy-now.component';
import { AnonymousOfferCreationFailedDialogComponent } from './components/anonymous-offer-creation-failed-dialog/anonymous-offer-creation-failed-dialog.component';
import { AnonymousOfferCreationSuccessDialogComponent } from './components/anonymous-offer-creation-success-dialog/anonymous-offer-creation-success-dialog.component';
import { AnonymousOfferDenyConfirmDialogComponent } from './components/anonymous-offer-deny-confirm-dialog/anonymous-offer-deny-confirm-dialog.component';
import { AnonymousOfferAcceptDialogComponent } from './components/anonymous-offer-accept-dialog/anonymous-offer-accept-dialog.component';
import { AnonymousOfferAcceptSuccessDialogComponent } from './components/anonymous-offer-accept-success-dialog/anonymous-offer-accept-success-dialog.component';
import { AddressImportDialogComponent } from './components/address-import-dialog/address-import-dialog.component';
import { AnonymousNegotiationFailedDialogComponent } from './components/anonymous-negotiation-failed-dialog/anonymous-negotiation-failed-dialog.component';
import { AnonymousNegotiationSuccessDialogComponent } from './components/anonymous-negotiation-success-dialog/anonymous-negotiation-success-dialog.component';
import { AskUserDialogComponent } from './components/ask-user-dialog/ask-user-dialog.component';
import { UserInformationDialogComponent } from './components/user-information-dialog/user-information-dialog.component';
import { CancelOrderDialogComponent } from './components/cancel-order-dialog/cancel-order-dialog.component';
import { SupplierDirectContentComponent } from './components/supplier-auction-anonymous-detail/supplier-direct-content/supplier-direct-content.component';
import { StatisticsContainerComponent } from './components/charts/statistics-container/statistics-container.component';
import { TopProducstsContainerComponent } from './components/charts/top-producsts-container/top-producsts-container.component';
import { OrderCompletedSuccessDialogComponent } from './components/order-completed-success-dialog/order-completed-success-dialog.component';
import { DownloadKravagThingComponent } from './components/table-detail-bottomsheet/complaints-modal/download-kravag-thing/download-kravag-thing.component';
import { GenieChatComponent } from './components/genie-chat/genie-chat.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { CrateInputComponent } from './pages/dashboard/order/direct/crate-input/crate-input.component';
import { LoadingMeterInputComponent } from './pages/dashboard/order/direct/loading-meter-input/loading-meter-input.component';
import { CrateCompareComponent } from './pages/dashboard/order/direct/crate-compare/crate-compare.component';
import { VehicleCompareComponent } from './pages/dashboard/order/direct/vehicle-compare/vehicle-compare.component';
import { VehicleInputComponent } from './pages/dashboard/order/direct/vehicle-input/vehicle-input.component';
import { RequestSuccessfulDeliveryDocsComponent } from './components/request-successful-delivery-docs/request-successful-delivery-docs.component';
import { ImportOfferComponent } from './components/import-offer/import-offer.component';
import { IframePage } from './pages/iframe/iframe.page';

@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: [
        LoadingMeterInputComponent,
        CrateInputComponent,
        NavigationComponent,
        LoadingOverlayComponent,
        ComparisationFormatterPipe,
        TableFilteringComponent,
        TableDetailBottomsheetComponent,
        ComplaintsModalComponent,
        CheckoutCompletionModalComponent,
        CheckoutAuctionAnonymousCompletionModalComponent,
        FooterComponent,
        CountUpDirective,
        InsuranceResultModalComponent,
        DropZoneFileUploadComponent,
        DndDirective,
        AnonymousAuctionContentComponent,
        DirectContentComponent,
        SurveyComponent,
        DetailsComponent,
        FirstViewComponent,
        EmailFormComponent,
        SavedComplaintComponent,
        UploadFilesComponent,
        AcceptLegalNoticeComponent,
        StatusComponent,
        SalesComponent,
        SupplierAuctionAnonymousDetailComponent,
        AnonymousAuctionSupplierContentComponent,
        BetTooLowDialogComponent,
        BetSuccessDialogComponent,
        BetDeleteDialogComponent,
        BetBuyNowComponent,
        AnonymousOfferCreationFailedDialogComponent,
        AnonymousOfferCreationSuccessDialogComponent,
        AnonymousOfferDenyConfirmDialogComponent,
        AnonymousOfferAcceptDialogComponent,
        AnonymousOfferAcceptSuccessDialogComponent,
        AddressImportDialogComponent,
        AnonymousNegotiationFailedDialogComponent,
        AnonymousNegotiationSuccessDialogComponent,
        AskUserDialogComponent,
        UserInformationDialogComponent,
        CancelOrderDialogComponent,
        SupplierDirectContentComponent,
        StatisticsContainerComponent,
        TopProducstsContainerComponent,
        OrderCompletedSuccessDialogComponent,
        DownloadKravagThingComponent,
        GenieChatComponent,
        CrateCompareComponent,
        VehicleCompareComponent,
        VehicleInputComponent,
        RequestSuccessfulDeliveryDocsComponent,
        ImportOfferComponent,
        IframePage
    ],
    imports: [
        IonicModule,
        MatButtonModule,
        MatAutocompleteModule,
        CommonModule,
        RouterModule,
        MatBottomSheetModule,
        MatTooltipModule,
        FormsModule,
        MatDatepickerModule,
        ReactiveFormsModule,
        TranslateModule,
        MatListModule,
        MatDialogModule,
        MatIconModule,
        MatTableModule,
        MatProgressSpinnerModule,
        MatCheckboxModule,
        MatPaginatorModule,
        MatSortModule,
        MatMenuModule,
        MatFormFieldModule,
        MatInputModule,
        MatRadioModule,
        NgChartsModule,
        MatTabsModule,
        SwiperModule,
        MatNativeDateModule,
        MatExpansionModule,
        NgxMaskModule.forChild(),
        NgxGdprCookieConsentModule,
        MatStepperModule,
        MatSelectModule,
        NgxMatIntlTelInputComponent,
        MatCardModule,
        MatProgressBarModule
    ],
    exports: [
        TranslateModule,
        MatButtonModule,
        MatTooltipModule,
        MatNativeDateModule,
        MatRadioModule,
        MatDialogModule,
        CrateInputComponent,
        RouterModule,
        FormsModule,
        MatIconModule,
        MatAutocompleteModule,
        ReactiveFormsModule,
        CommonModule,
        MatDatepickerModule,
        NavigationComponent,
        LoadingOverlayComponent,
        AcceptLegalNoticeComponent,
        ComparisationFormatterPipe,
        MatProgressSpinnerModule,
        SavedComplaintComponent,
        TableFilteringComponent,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatMenuModule,
        MatBottomSheetModule,
        MatFormFieldModule,
        MatInputModule,
        NgChartsModule,
        TableDetailBottomsheetComponent,
        SupplierAuctionAnonymousDetailComponent,
        LoadingMeterInputComponent,
        MatTabsModule,
        SwiperModule,
        ComplaintsModalComponent,
        MatExpansionModule,
        CheckoutCompletionModalComponent,
        CheckoutAuctionAnonymousCompletionModalComponent,
        NgxMaskModule,
        FooterComponent,
        CountUpDirective,
        InsuranceResultModalComponent,
        DropZoneFileUploadComponent,
        DndDirective,
        AnonymousAuctionContentComponent,
        DirectContentComponent,
        NgxGdprCookieConsentModule,
        SurveyComponent,
        MatStepperModule,
        DetailsComponent,
        MatSelectModule,
        NgxMatIntlTelInputComponent,
        FirstViewComponent,
        EmailFormComponent,
        UploadFilesComponent,
        StatusComponent,
        SalesComponent,
        AnonymousAuctionSupplierContentComponent,
        BetTooLowDialogComponent,
        BetSuccessDialogComponent,
        BetDeleteDialogComponent,
        BetBuyNowComponent,
        AnonymousOfferCreationFailedDialogComponent,
        AnonymousOfferCreationSuccessDialogComponent,
        AnonymousOfferDenyConfirmDialogComponent,
        AnonymousOfferAcceptDialogComponent,
        AnonymousOfferAcceptSuccessDialogComponent,
        AddressImportDialogComponent,
        AnonymousNegotiationFailedDialogComponent,
        AnonymousNegotiationSuccessDialogComponent,
        AskUserDialogComponent,
        UserInformationDialogComponent,
        CancelOrderDialogComponent,
        SupplierDirectContentComponent,
        StatisticsContainerComponent,
        TopProducstsContainerComponent,
        OrderCompletedSuccessDialogComponent,
        MatCardModule,
        DownloadKravagThingComponent,
        GenieChatComponent,
        MatProgressBarModule,
        CrateCompareComponent,
        VehicleCompareComponent,
        VehicleInputComponent,
        RequestSuccessfulDeliveryDocsComponent,
        ImportOfferComponent,
        IframePage
    ]
})
export class SharedModule {
    constructor(private _auth: AuthService) { }
}
