import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PriceFormatterService {

  constructor() { }
  formatPrice(price: number | string): string {
    let numericPrice: number;

    if (typeof price === 'string') {

      if (price.includes('.') && !price.includes(',')) {

        numericPrice = parseFloat(price.replace('.', ','));
      } else {

        const cleanedPrice = price.replace(/\./g, '').replace(',', '.');
        numericPrice = parseFloat(cleanedPrice);
      }
    } else {
      numericPrice = price;
    }


    return numericPrice.toLocaleString('de-DE', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  }
}
