<ion-header class="ion-no-border">
  <ion-toolbar color="none">
    <ion-buttons mode="ios" s slot="end">
      <ion-button mode="ios" class="ms-2" (click)="dismiss()" translate>
        <ion-icon name="close-circle-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="container ps-4 pe-4 pb-4">
    <div class="row">
      <div class="col-12" align="center">
        <h2>Unique Marketplaces by <span>Vehar<sup>&reg;</sup></span></h2>
      </div>
      <div class="col-12 mt-3 display-flex">
        <ion-icon class="check-icon" color="primary" name="checkmark-circle-outline"></ion-icon>
        <div class="success-text">Anzeige erfolgreich versendet</div>
      </div>

      <div class="col-12 col-lg-6 mt-4">
        <div class="item">
          <div class="heading" translate>offer_kind</div>
          <div class="content" *ngIf="data">{{data.product}}</div>
          <ion-skeleton-text *ngIf="!data" [animated]="true" style="width: 150px;height: 30px;"></ion-skeleton-text>
        </div>
      </div>
      <div class="col-12 col-lg-6 mt-4">
        <div class="item mt-2" *ngIf="data">

          <div class="heading" *ngIf="data.task_nr" translate>task_nr</div>
          <div class="heading" *ngIf="!data.task_nr" translate>offer_number</div>
          <div class="content" *ngIf="!data.task_nr">{{ data.offer_number }}</div>
          <div class="content" *ngIf="data.task_nr">{{ data.task_nr }}</div>

        </div>
        <ion-skeleton-text *ngIf="!data" [animated]="true" style="width: 150px;height: 30px;"></ion-skeleton-text>
      </div>
      <div class="col-12 col-lg-6 mt-3">
        <div class="item" *ngIf="data">
          <div class="heading" translate>status</div>
          <div class="content">{{ data.status | translate}}</div>
        </div>
        <ion-skeleton-text *ngIf="!data" [animated]="true" style="width: 150px;height: 30px;"></ion-skeleton-text>
      </div>
      <div class="col-12 col-lg-6 mt-3">
        <div class="item" *ngIf="mail">
          <div class="heading" translate>offer_detail_contact_us</div>
          <div class="content">
            <a [href]="'mailto:'+this.mail+'?subject='+getOfferNr()">{{this.mail}}</a>
          </div>

        </div>
        <ion-skeleton-text *ngIf="!mail" [animated]="true" style="width: 150px;height: 30px;"></ion-skeleton-text>

      </div>
      <div class="col-12 mt-3">
        <div class="map" [id]="'map'">
        </div>
      </div>
      <div class="col-12 mt-4">
        <h3 translate>order_submitted</h3>
        <div style="margin-top: -10px;" translate>order_submitted_text</div>
      </div>

    </div>
  </div>
</ion-content>