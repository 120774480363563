import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';

type UserAccountType = 'logistic' | 'customer';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  accountType?: UserAccountType;
  importEndpoint?: string;

  constructor(private _auth: AngularFireAuth,
    private _store: AngularFirestore) {

    _auth.authState.subscribe(user => {
      if (user?.uid) {
        this.loadUserClaims(user);
      }
    });
  }

  reloadUserClaims() {
    this._auth.authState.subscribe(user => {
      if (user?.uid) {
        this.loadUserClaims(user);
      }
    });
  }

  private async loadUserClaims(user: firebase.default.User) {
    const profile = (await this._store.collection('users').doc<any>(user.uid).get().toPromise()).data();
    if (profile?.accountType == 'logistic') {
      this.accountType = 'logistic';
    } else {
      this.accountType = 'customer';
    }
    this.importEndpoint = profile?.importEndpoint ?? '';
  }

  getType() {
    return this.accountType;
  }

  isLogistic() {
    return this.accountType == 'logistic';
  }

  isCustomer() {
    return this.accountType == 'customer';
  }

  hasImportEndpoint() {
    return this.importEndpoint != '';
  }
}
