import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-import-offer',
  templateUrl: './import-offer.component.html',
  styleUrls: ['./import-offer.component.scss'],
})
export class ImportOfferComponent implements OnInit {

  @ViewChild('stepper') stepper!: MatStepper;

  filePath = '';
  showLoader = true;

  constructor(private _http: HttpClient,
    private _router: Router,
    private _modal: ModalController
  ) { }

  ngOnInit() {
  }

  storeFilePath(path: string) {
    this.filePath = path;
  }

  async processFile() {
    this.showLoader = true;
    this.stepper.next();

    try {
      const result = await this._http.post<any>(`${environment.api_url}/import/pwlenzen`, {
        filePath: this.filePath
      }).toPromise();

      this._modal.dismiss();
      this._router.navigate(['/dashboard/order/direct/', result.id]);
    } catch (error) {

    }
  }
}
