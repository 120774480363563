import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AddressService } from 'src/app/services/address-service/address-service.service';
import { DateService } from 'src/app/services/date/date.service';
import { City } from 'src/app/types/address';
import { InsuranceDetails } from 'src/app/types/insurance-details';
import { PriceSettings } from 'src/app/types/settings';
import { debounceTime } from 'rxjs/operators';
import { CountryNotAllowedComponent } from '../dashboard/order/direct/country-not-allowed/country-not-allowed.component';
import dayjs from 'dayjs';
import { environment } from 'src/environments/environment';
import { IonContent } from '@ionic/angular';

@Component({
  selector: 'app-iframe',
  templateUrl: './iframe.page.html',
  styleUrls: ['./iframe.page.scss'],
})
export class IframePage implements OnInit {
  offerCreated = false;
  goBack() {
    this.offerCreated = false;
  }

  startPrice = 0;
  endPrice = 0;
  priceLoaded = false;
  planPriceData = [
    0,
    0,
    0,
    0,
    0
  ]
  id = ''
  loadingPrice = true;
  shippingPrice: any;
  activeTab = 0;

  @ViewChild('pickupType')
  pickupType!: HTMLElement;

  @ViewChild('contentContainer')
  contentContainer!: IonContent;

  reloadPage() {
    window.location.reload();
  }

  constructor(private _builder: FormBuilder,
    private dialog: MatDialog,
    public _date: DateService,
    private _http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private _store: AngularFirestore,
    public _translate: TranslateService,
    private _address: AddressService) {
  }

  alreadyFilledCrates = false;
  selectedPlan = 1;
  orderId = '';
  distance = 90;
  prefilledLoading = false;
  total = 0;
  crateExists = false;
  showLoader = false;
  timeslots = ['07:00 - 16:00', '08:00 - 17:00', '06:00 - 14:00', '09:00 - 15:00', '11:00 - 17:30']
  sendOptions = ["industrial_goods", "consum_goods", "print_goods", "machines", "land_and_forest", "car_goods", "building_goods", "additional_goods", "tobacco_over_200k", "mobiles_over_200k", "edv_over_200k", "communication_over_200k", "computer_over_200k", "peripherals_over_200k", "chips_over_200k", "liqour_over_200k", "jewelry", "docs", "drugs", "real_pearls", "metals", "stoans", "stuff_from_stoans_and_metals", "money", "juwels", "credits"]
  cities: City[] = [];
  additional_options: any = [];
  start_date = '';
  insuranceDetails: InsuranceDetails | undefined;
  allowedCountries: string[] = [];

  group = new FormGroup({
    selectedTab: new FormControl(0),
    selectedPlan: new FormControl(0),
    address_preselect: this._builder.group({
      from_address: ['', [Validators.required, Validators.minLength(10)]],
      to_address: ['', [Validators.required, Validators.minLength(10)]]
    }),
    address: new FormGroup({
      pickup: this._builder.group({
        companyname: [''],
        street: ['', Validators.required],
        country: ['', Validators.required],
        zipcode: ['', Validators.required],
        housenumber: ['', Validators.required],
        city: [{ disabled: true, value: '' }, Validators.required],
        contact: ['',],
        email: ['',],
        phone: ['', Validators.pattern('[- +()0-9]{10,16}')],
        notes: [''],
        reference: [''],
        neutral: [],
      }),
      deliver: this._builder.group({
        companyname: [''],
        street: ['', Validators.required],
        housenumber: ['', Validators.required],
        country: ['', Validators.required],
        zipcode: ['', Validators.required],
        city: [{ disabled: true, value: '' }, Validators.required,],
        contact: ['',],
        email: ['',],
        phone: ['', Validators.pattern('[- +()0-9]{10,16}')],
        notes: [''],
        reference: [''],
        neutral: []
      })
    }),
    additional_information: this._builder.group({
      delivery_kind: ['b2b'],
      start_date: ['', [Validators.required]],
      end_date: [{ value: '', disabled: true }, [Validators.required]],
      start_time: ['', [Validators.required]],
      pickup_end_date: [,],
      pickup_start_date: [,],
      deliver_end_date: [,],
      deliver_start_date: [],
      end_time: ['', [Validators.required]],
      what_send: ['', Validators.required],
      internal_reference: [''],
      additional_options: [[]],
    }),
    vehicles: this._builder.group({
      vehicleType: [0, [Validators.required]],
    }),
    crates: this._builder.group({
      crates: this._builder.array([]),
      loading_meter: [0.4, []],
      weight: [300,]
    }),
    loading_kind: this._builder.group({
      unloading_kind: ['', [Validators.required]],
      loading_kind: ['', [Validators.required,]],
      car: [{ readonly: true, value: 'sprinter' }, [Validators.required]]
    }),
    loading_meter: this._builder.group({
      loading_meter: [13.6, [Validators.required, Validators.max(13.6)]],
      weight: [24000, [Validators.required, Validators.max(24000)]]
    })
  })

  ngAfterViewInit() {
    this.group.markAllAsTouched();
    this.activeTab = this.activatedRoute.snapshot.queryParams['activeTab'];
    if (this.activeTab) {
      this.group.get('selectedTab')?.setValue(this.activeTab);

      setTimeout(() => {
        const activeTabElement = document.getElementsByClassName('mdc-tab')[this.activeTab] as HTMLElement;
        activeTabElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      }, 600);
    }
  }

  async loadAllowedCountries() {
    try {
      const result = await this._store.collection<PriceSettings>('settings').doc('price').get().toPromise();
      this.allowedCountries = Object.keys(result.data()!.country_gesperrt).filter(key => !result.data()!.country_gesperrt[key]);
    } catch (error) { }
  }

  getFormGroup(name: string) {
    return this.group.get(name) as FormGroup;
  }

  getFromAddress() {
    if (this.getFormGroup('address_preselect').get('from_address')?.value) {
      const value: any = this.getFormGroup('address_preselect').get('from_address')?.value;
      if (value.zipcode) {
        return `${value.zipcode} ${value.city}`
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(_: any) {
    this.resizeIframe();
  }

  getLDM() {
    if (this.group.get('selectedTab')?.value == 0) {
      return `${this.getFormGroup('crates').get('loading_meter')?.value?.toFixed(2) ?? 0} ldm`
    } else {
      return `${this.getFormGroup('loading_meter').get('loading_meter')?.value?.toFixed(2) ?? 0} ldm`
    }
  }

  getLoadingMetreForCheckout() {
    if (this.group.get('selectedTab')?.value == 0) {
      return `${this.getFormGroup('crates').get('loading_meter')?.value?.toFixed(2) ?? 0} ldm`
    } else {
      return `${this.getFormGroup('loading_meter').get('loading_meter')?.value?.toFixed(2) ?? 0} ldm`
    }
  }

  setLang(lang: string) {
    this._translate.use(lang);
  }

  getTotalWeightForCheckout() {
    if (this.group.get('selectedTab')?.value == 2) {
      switch (this.selectedPlan) {
        case 0:
          return "800 kg"
        case 1:
          return "3.000 kg"
        case 2:
          return "5.500 kg"
        case 3:
          return "24.000 kg"
      }
    }
    if (this.group.get('selectedTab')?.value == 0) {
      return `${this.getFormGroup('crates').get('weight')?.value ?? 0} kg`
    } else {
      return `${this.getFormGroup('loading_meter').get('weight')?.value ?? 0} kg`
    }
  }



  getKG() {
    if (this.group.get('selectedTab')?.value == 0) {
      return `${this.getFormGroup('crates').get('weight')?.value ?? 0} kg`
    } else {
      return `${this.getFormGroup('loading_meter').get('weight')?.value ?? 0} kg`
    }
  }


  checkIfCountryBlocked() {
    const from = this.getFormGroup('address_preselect').get('from_address')?.value;
    const to = this.getFormGroup('address_preselect').get('to_address')?.value;
    if (from && to) {

    }
  }

  getToAddress() {
    if (this.getFormGroup('address_preselect').get('to_address')?.value) {
      const value: any = this.getFormGroup('address_preselect').get('to_address')?.value;
      if (value.zipcode) {
        return `${value.zipcode} ${value.city}`
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  resizeIframe() {
    window.parent?.postMessage({ height: document.body.scrollHeight }, '*');
  }


  flipAddress() {
    const from_address = this.getFormGroup('address_preselect').get('from_address')?.value,
      to_address = this.getFormGroup('address_preselect').get('to_address')?.value;
    if (from_address && to_address) {
      this.getFormGroup('address_preselect').get('from_address')?.setValue(to_address);
      this.getFormGroup('address_preselect').get('to_address')?.setValue(from_address);
    }
  }



  async autofillCity(field: string) {
    const city = await this._address.loadCityFromProfile();
    this.getFormGroup('address_preselect').get(field)?.setValue(city);
  }

  calculateShipping() {
    this.showLoader = true;
    this.getFormGroup('loading_kind').reset();
    this.getPrices();
    this.getDistance();
  }

  ngOnInit() {
    const id = this.activatedRoute.snapshot.params['id'];
    if (id) {
      this.prefilledLoading = true
      this.orderId = id;
    }


    this.getFormGroup('address_preselect').get('from_address')?.valueChanges.pipe(
      debounceTime(600)
    ).subscribe(search => this.updateSearch(search));
    this.getFormGroup('address_preselect').get('to_address')?.valueChanges.pipe(
      debounceTime(600)
    ).subscribe(search => this.updateSearch(search));
    this.loadAllowedCountries();
    this.resizeIframe();
  }


  getCityName(city: any) {
    if (!city) {
      return '';
    }
    return `${city.zipcode} ${city.city}, ${city.country_code}`;
  }

  async updateSearch(searchString: string | null) {
    if (searchString && searchString.length > 1) {
      const results = await this._address.findResults(searchString);
      this.cities = results;
    } else {
      this.cities = [];
    }
  }

  checkIfCityInGermany($event: any) {
    if (String($event.option.value.country_code).toUpperCase() != 'DE' && !this.allowedCountries.includes($event.option.value.country_code)) {
      this.dialog.open(CountryNotAllowedComponent, { width: '400px' });
      this.getFormGroup('address_preselect').get('from_address')?.setValue(null);
      this.getFormGroup('address_preselect').get('to_address')?.setValue(null);
    } else {
      if (this.getFormGroup('address_preselect').get('to_address')?.value) {
        const value: any = this.getFormGroup('address_preselect').get('to_address')?.value;
        if (value.zipcode) {
          this.getFormGroup('address').get('deliver')?.get('city')?.setValue(value.city);
          this.getFormGroup('address').get('deliver')?.get('zipcode')?.setValue(value.zipcode);
          this.getFormGroup('address').get('deliver')?.get('country')?.setValue(value.country_code);
        }
      }
      if (this.getFormGroup('address_preselect').get('from_address')?.value) {
        const value: any = this.getFormGroup('address_preselect').get('from_address')?.value;
        if (value.zipcode) {
          this.getFormGroup('address').get('pickup')?.get('city')?.setValue(value.city);
          this.getFormGroup('address').get('pickup')?.get('zipcode')?.setValue(value.zipcode);
          this.getFormGroup('address').get('pickup')?.get('country')?.setValue(value.country_code);
        }
      }
    }
  }

  getDistance() {
    const start: any = this.getFormGroup('address_preselect').get('from_address')?.value;
    const destination: any = this.getFormGroup('address_preselect').get('to_address')?.value;
    this._http.put<any>(`${environment.api_url}/distance`, {
      start,
      destination

    }).subscribe(result => {
      this.distance = result.distance;
      this._date.distance.next(this.distance)
    })
  }



  updateEndDate($event: any) {
    if (this.group.get('selectedTab')?.value !== 2) {
      if (this.selectedPlan == 0 || this.selectedPlan == 3) {
        this.start_date = this._date.getRangeForPlan1(new Date($event.target.value));
        this.getFormGroup('additional_information').get('pickup_start_date')?.setValue(this._date.getStartDateForPlan1(new Date($event.target.value)))
        this.getFormGroup('additional_information').get('deliver_start_date')?.setValue(this._date.getStartDateForPlan1(new Date($event.target.value)))
        this.getFormGroup('additional_information').get('pickup_end_date')?.setValue(this._date.getEndDateForPlan1Date(new Date($event.target.value)))
        this.getFormGroup('additional_information').get('deliver_end_date')?.setValue(this._date.getEndDateForPlan1Date(new Date($event.target.value)))
        this.getFormGroup('additional_information').get('end_date')?.setValue(this._date.getRangeForPlan1(new Date($event.target.value)));
      }
      if (this.selectedPlan == 1) {
        this.start_date = this._date.getEndDateRangeForPlan2(new Date($event.target.value), this.distance);
        this.getFormGroup('additional_information').get('pickup_start_date')?.setValue(this._date.getStartDateForPlan2(new Date($event.target.value), this.distance))
        this.getFormGroup('additional_information').get('deliver_start_date')?.setValue(this._date.getStartDateForPlan2(new Date($event.target.value), this.distance))
        this.getFormGroup('additional_information').get('pickup_end_date')?.setValue(this._date.getEndDateForPlan2Date(new Date($event.target.value), this.distance))
        this.getFormGroup('additional_information').get('deliver_end_date')?.setValue(this._date.getEndDateForPlan2Date(new Date($event.target.value), this.distance))
        this.getFormGroup('additional_information').get('end_date')?.setValue(this._date.getEndDateRangeForPlan2(new Date($event.target.value), this.distance));
      }
      if (this.selectedPlan == 2) {

        this.getFormGroup('additional_information').get('pickup_start_date')?.setValue(this._date.getStartDateForPlan3(new Date($event.target.value),))
        this.getFormGroup('additional_information').get('deliver_start_date')?.setValue(this._date.getEndDateForPlan3Date(new Date($event.target.value),))
        this.getFormGroup('additional_information').get('pickup_end_date')?.setValue(this._date.getStartDateForPlan3(new Date($event.target.value)))
        this.getFormGroup('additional_information').get('deliver_end_date')?.setValue(this._date.getEndDateForPlan3Date(new Date($event.target.value)))
        this.start_date = dayjs(new Date($event.target.value)).format('DD.MM.YYYY');

        this.getFormGroup('additional_information').get('end_date')?.setValue(dayjs(this._date.getEndDateForPlan3Date(new Date($event.target.value))).format('DD.MM.YYYY'))
      }
    } else {
      const startDate = this._date.getStartDateForPlan2(new Date($event.target.value), this.distance);
      this.getFormGroup('additional_information').get('pickup_start_date')?.setValue(startDate)
      this.getFormGroup('additional_information').get('deliver_start_date')?.setValue(startDate)
      const endDate = this._date.getEndDateForPlan2Date(new Date($event.target.value), this.distance);
      this.getFormGroup('additional_information').get('pickup_end_date')?.setValue(endDate)
      this.getFormGroup('additional_information').get('deliver_end_date')?.setValue(endDate)

      this.start_date = this._date.getEndDateRangeForPlan2(new Date($event.target.value), this.distance);
      this.getFormGroup('additional_information').get('end_date')?.setValue(this._date.getEndDateRangeForPlan2(new Date($event.target.value), this.distance));
    }
  }


  changeTab(event: any) {
    setTimeout(() => {
      this.group.get('selectedTab')?.setValue(event.index);
    }, 100)
  }


  getValueForAdditionalOption(option: string) {
    if (this.additional_options.filter((object: any) => object.name == option).length > 0) {
      return this.additional_options.filter((object: any) => object.name == option)[0]
    }
    return {}
  }
  isActivated(option: string) {
    if (this.additional_options.findIndex((object: any) => object.name == option) != -1) {
      return true;
    }
    return false;
  }

  calculatePrice($event: any) {
    if ($event.selectedIndex == 2 || $event.selectedIndex == 4) {
      this.loadingPrice = true;
      this.getPrice();
    }


  }

  updatePlan($event: any,) {
    this.selectedPlan = $event.plan;
    this.group.get('selectedPlan')?.setValue($event.plan);
    this.getFormGroup('loading_kind').get('loading_kind')?.setValue($event.loading_kind);
    this.getFormGroup('loading_kind').get('unloading_kind')?.setValue($event.unloading_kind);
    this.getFormGroup('loading_kind').get('car')?.setValue($event.car);
    this.getFormGroup('vehicles').get('vehicleType')?.setValue($event.plan);
    this.getFormGroup('additional_information').get('pickup_start_date')?.setValue(null);
    this.getFormGroup('additional_information').get('pickup_end_date')?.setValue(null);
    this.getFormGroup('additional_information').get('deliver_start_date')?.setValue(null);
    this.getFormGroup('additional_information').get('deliver_end_date')?.setValue(null);
    this.getFormGroup('additional_information').get('start_date')?.setValue(null);
    this.getFormGroup('additional_information').get('end_date')?.setValue(null);
    this.getFormGroup('additional_information').get('start_time')?.setValue(null);
    this.getFormGroup('additional_information').get('end_time')?.setValue(null);
    this.start_date = '';

    if (this.group.get('selectedTab')?.value == 2) {
      this.timeslots = ['07:00 - 16:00', '08:00 - 12:00', '08:00 - 17:00', '06:00 - 14:00', '09:00 - 15:00', '11:00 - 17:30', '16:00 - 19:00']
    }
    if (this.group.get('selectedTab')?.value == 1) {
      this.timeslots = ['07:00 - 16:00', '08:00 - 17:00', '06:00 - 14:00', '09:00 - 15:00', '11:00 - 17:30'];
    }
    if (this.selectedPlan == 0 || this.selectedPlan == 2) {
      this.timeslots = ['07:00 - 16:00', '08:00 - 17:00', '06:00 - 14:00', '09:00 - 15:00', '11:00 - 17:30'];
    } else {
      this.timeslots = ['07:00 - 16:00', '08:00 - 12:00', '08:00 - 17:00', '06:00 - 14:00', '09:00 - 15:00', '11:00 - 17:30', '16:00 - 19:00']
    }
  }




  getPrice() {
    this._http.post<any>(`${environment.api_url}/direct/price`, this.group.getRawValue()).subscribe(result => {
      this.shippingPrice = Number(result.price);
      this.loadingPrice = false;
    })
  }

  async getPrices() {
    this._http.post(`${environment.api_url}/direct/options/price`, this.group.value).subscribe((result: any) => {
      this.planPriceData = result.map((option: any) => option.price.replace('.', ','));
      this.startPrice = this.planPriceData[0]
      this.endPrice = this.planPriceData[3]
      this.priceLoaded = true;
      this.showLoader = false;
      setTimeout(() => {
        try {
          this.offerCreated = true;
          this.resizeIframe();
          this.contentContainer.scrollToTop(100);
        } catch (err) {
          console.error('Scrollen fehlgeschlagen:', err);
        }
      }, 300)
    })

  }
}
