<ion-header>
  <ion-toolbar>
    <figure>
      <img src="assets/logo/logo-transparent.png" routerLink="/dashboard/order" height="35" /><br />
      <figcaption>logistiko marketplace</figcaption>
    </figure>

    <ion-buttons slot="end" class="me-3">
      <ion-button id="profile-center" shape="round">
        <ion-icon name="person-circle-outline"></ion-icon>
        <ion-icon name="caret-down-outline" class="caret"></ion-icon>
      </ion-button>
      <ion-button id="language-center" shape="round">
        <img src="assets/countries/{{ currentLang }}.svg" height="20" width="30" style="margin: 0px" />
        <ion-icon name="caret-down-outline" class="caret ms-1"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

  <ion-popover trigger="profile-center" triggerAction="click" #profilePopoer>
    <ng-template>
      <ion-content>
        <ion-list lines="none">
          <ion-item button>
            <ion-label>
              <span translate>signed_in_as</span><br />
              <small>{{ signed_in_mail }}</small>
            </ion-label>
          </ion-item>
          <ion-item button routerLink="/dashboard/home/statistics" (click)="profilePopoer.dismiss()"
            *ngIf="this._user.isCustomer()">
            <ion-label>
              <span>Dashboard</span>
            </ion-label>
          </ion-item>
          <ion-item button (click)="navigate('dashboard/logistic/statistics', true)" *ngIf="this._user.isLogistic()">
            <ion-label>
              <span>Dashboard</span>
            </ion-label>
          </ion-item>
          <ion-item button routerLink="/dashboard/profile" (click)="profilePopoer.dismiss()">
            <ion-label>
              <span translate>my_profile</span>
            </ion-label>
          </ion-item>
          <ion-item button (click)="logout(); profilePopoer.dismiss()">
            <ion-icon name="log-out-outline"></ion-icon>
            <ion-label class="ms-3" translate>logout</ion-label>
          </ion-item>
        </ion-list>
      </ion-content>
    </ng-template>
  </ion-popover>

  <ion-popover trigger="language-center" triggerAction="click">
    <ng-template>
      <ion-content>
        <p class="m-3" translate>language_select</p>
        <ion-list lines="none">
          <ion-item button (click)="setLang('de')">
            <img src="assets/countries/de.svg" height="20" width="30" style="margin: 0px" />
            <ion-label class="ms-3">Deutsch</ion-label>
            <ion-icon slot="end"
              [name]="currentLang === 'de' ? 'radio-button-on-outline' : 'radio-button-off-outline'"></ion-icon>
          </ion-item>
          <ion-item button (click)="setLang('en')">
            <img src="assets/countries/en.svg" height="20" width="30" style="margin: 0px" />
            <ion-label class="ms-3">English</ion-label>
            <ion-icon slot="end"
              [name]="currentLang === 'en' ? 'radio-button-on-outline' : 'radio-button-off-outline'"></ion-icon>
          </ion-item>
        </ion-list>
      </ion-content>
    </ng-template>
  </ion-popover>
</ion-header>

<div class="side-bar" [class.collapsed]="!menuExpanded">
  <div class="top-buttons">
    <div class="text-center mb-4">
      <ion-icon class="side-top-icon" *ngIf="!menuExpanded"
        [name]="this._user.isLogistic() ? 'boat-outline' : 'accessibility-outline'" matTooltipPosition="right"
        [matTooltip]="(this._user.isLogistic() ? 'supplier' : 'customer') | translate"></ion-icon>
      <h2 class="side-title mb-2" *ngIf="menuExpanded">{{ (this._user.isLogistic() ? 'supplier' : 'customer') |
        translate }}</h2>
    </div>

    <div class="side-btn" [class.active]="activePage.startsWith('order')" (click)="navigate('dashboard/order')"
      *ngIf="this._user.isCustomer()">
      <ion-icon name="add-outline" class="side-btn-icon"></ion-icon>
      <span class="side-btn-text" translate>new_offer</span>
      <span class="tooltip" translate>new_offer</span>
    </div>

    <div class="side-btn" (click)="importOffer()" *ngIf="this._user.hasImportEndpoint()">
      <ion-icon name="cloud-upload-outline" class="side-btn-icon"></ion-icon>
      <span class="side-btn-text" translate>import_offer</span>
      <span class="tooltip" translate>import_offer</span>
    </div>

    <div *ngIf="this._user.isCustomer()">
      <div class="side-btn" [class.active]="activePage.startsWith('home/direct')"
        (click)="navigate('dashboard/home/direct')">
        <ion-icon src="assets/icon/direct.svg" class="side-btn-icon"></ion-icon>
        <span class="side-btn-text" [innerHTML]="'offer_vehar_direct' | translate"></span>
        <span class="tooltip" [innerHTML]="'offer_vehar_direct' | translate"></span>
      </div>

      <div class="side-btn" [class.active]="activePage.startsWith('home/anonymous')"
        (click)="navigate('dashboard/home/anonymous')">
        <ion-icon src="assets/icon/anonymous.svg" class="side-btn-icon"></ion-icon>
        <span class="side-btn-text" [innerHTML]="'offer_vehar_anonymous' | translate"></span>
        <span class="tooltip" [innerHTML]="'offer_vehar_anonymous' | translate"></span>
      </div>

      <div class="side-btn" [class.active]="activePage.startsWith('home/auction')"
        (click)="navigate('dashboard/home/auction')">
        <ion-icon src="assets/icon/auction.svg" class="side-btn-icon"></ion-icon>
        <span class="side-btn-text" [innerHTML]="'offer_vehar_auction' | translate"></span>
        <span class="tooltip" [innerHTML]="'offer_vehar_auction' | translate"></span>
      </div>
    </div>

    <div *ngIf="this._user.isLogistic()">
      <div class="side-btn" [class.active]="activePage.startsWith('logistic/direct')"
        (click)="navigate('dashboard/logistic/direct', true)">
        <ion-icon src="assets/icon/direct.svg" class="side-btn-icon"></ion-icon>
        <span class="side-btn-text" [innerHTML]="'offer_vehar_direct' | translate"></span>
        <span class="tooltip" [innerHTML]="'offer_vehar_direct' | translate"></span>
      </div>

      <div class="side-btn" [class.active]="activePage.startsWith('logistic/anonymous')"
        (click)="navigate('dashboard/logistic/anonymous', true)">
        <ion-icon src="assets/icon/anonymous.svg" class="side-btn-icon"></ion-icon>
        <span class="side-btn-text" [innerHTML]="'offer_vehar_anonymous' | translate"></span>
        <span class="tooltip" [innerHTML]="'offer_vehar_anonymous' | translate"></span>
      </div>

      <div class="side-btn" [class.active]="activePage.startsWith('logistic/auction')"
        (click)="navigate('dashboard/logistic/auction', true)">
        <ion-icon src="assets/icon/auction.svg" class="side-btn-icon"></ion-icon>
        <span class="side-btn-text" [innerHTML]="'offer_vehar_auction' | translate"></span>
        <span class="tooltip" [innerHTML]="'offer_vehar_auction' | translate"></span>
      </div>
    </div>

    <div class="side-btn" [class.active]="activePage.startsWith('dashboard/help')"
      (click)="navigate('dashboard/help', true)">
      <ion-icon name="help-outline" class="side-btn-icon"></ion-icon>
      <span class="side-btn-text" [innerHTML]="'questions' | translate"></span>
      <span class="tooltip" [innerHTML]="'questions' | translate"></span>
    </div>

    <div class="collapse-btn" (click)="menuExpanded = !menuExpanded">
      <ion-icon [name]="menuExpanded ? 'chevron-back-outline' : 'chevron-forward-outline'"></ion-icon>
    </div>
  </div>

  <div class="bottom-buttons">

    <div class="side-btn" (click)="openFAQ()">
      <ion-icon src="assets/icon/helpcenter.svg" class="side-btn-icon"></ion-icon>
      <span class="side-btn-text" translate>help</span>
      <span class="tooltip" translate>help</span>
    </div>
  </div>
</div>