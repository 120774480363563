import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastController } from '@ionic/angular';
import { environment } from 'src/environments/environment';

import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

export class FileUpload {
  key: string = "";
  name: string = "";
  url: string = "";
  file: File;

  constructor(file: File) {
    this.file = file;
  }
}

@Component({
  selector: 'app-drop-zone-file-upload',
  templateUrl: './drop-zone-file-upload.component.html',
  styleUrls: ['./drop-zone-file-upload.component.scss'],
})
export class DropZoneFileUploadComponent implements OnInit {

  isUploading = false;
  isUploaded = false;
  progress = 0;
  fileName = null;
  group = new FormGroup({
    message: new FormControl('', [Validators.maxLength(1000)])
  });

  @ViewChild('fileDropRef')
  fileUpload!: ElementRef;

  @Input()
  disabled: boolean = false;

  @Input()
  title: string = '';

  @Input()
  generatedUrl = true;

  private allowedExtensions: string[] = [];
  private url = '';



  @Input()
  acceptFilter: string = 'application/pdf';

  @Output()
  didUpload: EventEmitter<string> = new EventEmitter();
  @Output()
  didUploadFileUrl: EventEmitter<string> = new EventEmitter();
  @Output()
  getBase64: EventEmitter<any> = new EventEmitter();

  constructor(private _http: HttpClient,
    private _toast: ToastController,
    private _storage: AngularFireStorage) { }

  ngOnInit() {
    this.allowedExtensions = this.acceptFilter.split(',');
  }

  async onFileDropped($event: any) {
    if ($event?.length == 1) {
      if (this.allowedExtensions.includes($event[0].type)) {
        this.uploadFileToServer($event[0]);
        return;
      } else {

        (await this._toast.create({
          message: `Ungültiger Dateityp`,
          duration: 3000
        })).present();
      }
    }
    this.fileUpload.nativeElement.value = "";
  }

  fileBrowseHandler($event: any) {
    this.uploadFileToServer($event.target.files[0]);
  }

  async uploadFileToServer(file: any) {
    if (file.size > 10000000) {
      this.fileUpload.nativeElement.value = "";
      (await this._toast.create({
        message: "Datei überschreitet die 10MB Grenze, bitte versuche es erneut!",
        duration: 3000
      })).present();
      return;
    }

    this.isUploading = true;

    const formData = new FormData();
    formData.append("file", file);

    this.fileUpload.nativeElement.value = "";

    try {
      this.pushFileToStorage(new FileUpload(file)).subscribe(progress => {
        this.progress = progress ?? 0;
      })
    } catch (error) {
      this.progress = 0;
      this.isUploading = false;
      (await this._toast.create({
        message: 'Datei upload fehlgeschlagen, bitte versuche es erneut!',
        duration: 3000
      })).present();
    }
  }

  pushFileToStorage(fileUpload: FileUpload): Observable<number | undefined> {
    const filePath = `tmp/${fileUpload.file.name}`;
    const uploadTask = this._storage.upload(filePath, fileUpload.file);
    const reader = new FileReader();

    reader.onload = (e: any) => {
      // Die Dateidaten befinden sich in e.target.result
      const fileData = e.target.result;
      this.getBase64.emit({
        filename: fileUpload.name,
        mimetype: fileUpload.file.type,
        data: fileData
      })
      // Hier können Sie die Dateidaten weiterverarbeiten, z.B. sie an einen Server senden oder lokal anzeigen
    };
    reader.readAsDataURL(fileUpload.file);
    uploadTask.snapshotChanges().pipe(
      finalize(() => {
        this.didUploadFileUrl.emit(filePath);
        if (this.generatedUrl)
          this.retrieveDownloadURL(fileUpload);
        else {
          this.isUploading = false;
          this.progress = 0;
        }
      })
    ).subscribe();

    return uploadTask.percentageChanges();
  }

  async retrieveDownloadURL(fileUpload: FileUpload) {
    const result = await this._http.post<any>(`${environment.api_url}/files`, {
      filename: fileUpload.file.name
    }).toPromise();
    this.progress = 0;
    this.isUploading = false;
    this.url = result.url;
    this.didUpload.emit(result.url);

    (await this._toast.create({
      message: 'Datei wurde erfolgreich hochgeladen!',
      duration: 3000
    })).present();
    this.isUploaded = true;
  }

  resetForm() {
    this.group.reset();
    this.isUploading = false;
    this.isUploaded = false;
    this.didUpload.emit('');
    this.didUploadFileUrl.emit('');
  }

  openPreview() {
    window.open(this.url);
  }
}
