import { HttpClient } from '@angular/common/http';
import { AfterContentChecked, AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { Preferences } from '@capacitor/preferences';
import { LoadingController, NavController, ViewDidEnter } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import { DateService } from 'src/app/services/date/date.service';
import { environment } from 'src/environments/environment';
const plan_rules = require('../../../../../../assets/direct-plan-rules.json');
@Component({
  selector: 'app-crate-compare',
  templateUrl: './crate-compare.component.html',
  styleUrls: ['./crate-compare.component.scss'],
})
export class CrateCompareComponent implements AfterContentChecked, AfterViewInit {
  showOverSizeAlert = false;
  showClassic = true;
  showPoint = true;
  showHubflex = true;
  showDynamic = true;

  constructor(private _builder: FormBuilder, public _date: DateService,
    private _loading: LoadingController,
    private _translate: TranslateService,
    private _http: HttpClient,
    private _nav: NavController

  ) { }

  currentYear = dayjs().year();
  @Input()
  stepper!: MatStepper;
  @Input()
  isDirect: boolean = false;

  @Input()
  selectedPickupType: number | undefined


  @Output()
  onBackClicked: EventEmitter<boolean> = new EventEmitter();
  @Output()
  planSelected: EventEmitter<number> = new EventEmitter();
  @Input()
  group: FormGroup | undefined
  @Input()
  planPriceData = [
    0,
    0,
    0,
    0,
    0
  ]
  plan2EndDateRange = '';
  plan2StartDate = '';
  updatePickupType = true;
  plan1Possible = false;
  plan2Possible = false;
  plan3Possible = false;
  plan4Possible = false;
  plan3Range = '';



  findFirstAvailableDate(selectedPlan: any): Date | null {
    let date = dayjs().startOf('day');

    if (selectedPlan == 2) {
      date = dayjs().add(1, 'd');

      while (this._date.isPubicHoliday(date.toDate()) || date.day() == 0 || date.day() == 6) {


        date = date.add(1, 'd');
      }

      return date.toDate();
    }


    while (!this._date.validateStartDate(date.toDate(), selectedPlan, 0)) {
      date = date.add(1, 'day');
    }
    return date.toDate();
  }

  loading_kind_form = this._builder.group({
    unloading_kind: ['', [Validators.required]],
    loading_kind: ['', [Validators.required,]],
    car: [{ disabled: true, value: 'sprinter' }, [Validators.required]]
  });



  loading_kinds = ['loading_kind_sender', 'loading_kind_side', 'loading_kind_top', 'loading_by_sender'];
  unloading_kinds = ['unloading_kind_sender', 'unloading_kind_top', 'unloading_lift', 'unloading_kind_side'];
  cars = ['sprinter', 'with_edscha']

  ngAfterViewInit(): void {
    if (!this.stepper) {
      this.loading_kind_form.markAllAsTouched();
    }


    this.getEndDateRangeForPlan2();
    this.getDatesForPlan3();

  }

  getEndDateRangeForPlan2() {
    this._date.distance.subscribe(distance => {
      const start = this._date.getStartDateForPlan2(this.findFirstAvailableDate(1)!, distance);
      this.plan2StartDate = this._date.getEndDateRangeForPlan2(this.findFirstAvailableDate(1)!, distance);
      this.plan2EndDateRange = this.formatDate(start)
      if (this.isDirect) {
        this.updatePlanDisplay();
      }

    })
  }
  updatePlanDisplay() {
    const pickup = this.group?.get('address')?.get('pickup')?.value;
    const deliver = this.group?.get('address')?.get('deliver')?.value;
    if (pickup.country !== 'DE' && deliver.country !== 'DE') {
      this.showDynamic = false;
      this.showHubflex = false;
      this.showPoint = false;
      return;
    }
    if (pickup.country !== 'DE' || deliver.country !== 'DE') {
      let foreignCountry = pickup.country;
      if (pickup.country == 'DE') {
        foreignCountry = deliver.country;
      }
      const allowedPlans = plan_rules.rules.filter((object: any) => object.country == foreignCountry)[0]?.allowed_plans ?? [];
      this.showHubflex = allowedPlans.includes('hubflex');
      this.showClassic = allowedPlans.includes('classic');
      this.showPoint = allowedPlans.includes('point');
      this.showDynamic = allowedPlans.includes('dynamic');
      return
    }
    this.showClassic = true;
    this.showDynamic = true;
    this.showPoint = true;
    this.showHubflex = true;
    return;
  }

  formatDate(start: Date): string {
    return `${dayjs(start).format('DD.MM.YYYY')} `;
  }

  getDatesForPlan3() {
    const start = this.findFirstAvailableDate(2)!;

    this.plan3Range = this._date.getRangeForPlan3(start)
  }
  ngAfterContentChecked(): void {

    if (this.selectedPickupType == 1 && this.loading_kinds.length == 4) {
      this.loading_kind_form = this._builder.group({
        unloading_kind: ['', [Validators.required]],
        loading_kind: ['', [Validators.required,]],
        car: [{ disabled: true, value: 'sprinter' }, [Validators.required]]
      });

      this.loading_kinds.push('loading_top');
      this.unloading_kinds.push('unloading_top');
    }
    if (this.selectedPickupType == 0 && this.loading_kinds.length == 5) {
      this.loading_kind_form = this._builder.group({
        unloading_kind: ['', [Validators.required]],
        loading_kind: ['', [Validators.required,]],
        car: [{ disabled: true, value: 'sprinter' }, [Validators.required]]
      });
      this.loading_kinds.splice(4, 1);
      this.unloading_kinds.splice(4, 1);
    }
  }

  goBack() {
    if (this.stepper) {
      this.stepper.previous();
    } else {
      this.onBackClicked.emit(true);
    }

  }
  formatTimeFrame(date1: Date, date2: Date) {
    return `${dayjs(date1).format('DD.MM.YYYY')} - ${dayjs(date2).format('DD.MM.YYYY')}`;
  }
  selectPlan(plan: number) {
    const data: any = {
      unloading_kind: this.loading_kind_form.controls['unloading_kind'].value,
      loading_kind: this.loading_kind_form.controls['loading_kind'].value,
      car: this.loading_kind_form.controls['car'].value,
      plan: plan
    }
    this.planSelected.emit(data);
    if (this.stepper) {
      this.stepper.next();
    } else {
      this.storeAsSession();
    }

  }


  async storeAsSession() {
    const loadingMessage = await this._translate.get('loading_controller_text').toPromise();
    const loader = await this._loading.create({
      message: loadingMessage
    });

    await loader.present();

    this._http.post<any>(`${environment.api_url}/session`, this.group?.getRawValue()).subscribe((result: any) => {
      loader.dismiss();
      Preferences.set({ key: 'session', value: result.sessionId });

      // Öffne die URL im gleichen Tab
      window!.top!.location.href = `https://vehar-logistik.de/dashboard/order?sessionId=${result.sessionId}`;
    });
  }


  loadingKindChanged($event: any) {
    if ($event.value == 'loading_top' || (this.loading_kind_form.controls['unloading_kind'].value && this.loading_kind_form.controls['unloading_kind'].value == 'unloading_top')) {
      this.loading_kind_form.controls['car'].setValue('with_edscha')
    } else {
      this.loading_kind_form.controls['car'].setValue('sprinter')
    }
    if (($event.value == 'loading_kind_side' || $event.value == 'loading_kind_top') && this.loading_kind_form.controls['unloading_kind'].value) {
      this.plan4Possible = true;
      this.showOverSizeAlert = true;
      this.plan1Possible = false;
      this.plan2Possible = false;
      this.plan3Possible = false;

    } else {
      if (this.loading_kind_form.controls['unloading_kind'].value && (this.loading_kind_form.controls['unloading_kind'].value == 'unloading_kind_top' || this.loading_kind_form.controls['unloading_kind'].value == 'unloading_lift')) {
        this.plan4Possible = true;
        this.plan1Possible = false;
        this.plan2Possible = false;
        this.plan3Possible = false;
        this.showOverSizeAlert = true;
      } else {
        this.showOverSizeAlert = false;
        this.plan4Possible = false;
        this.plan1Possible = true;
        this.plan2Possible = true;
        this.plan3Possible = true;
      }
    }

  }


  unloadingKindChanged($event: any) {
    if ($event.value == 'unloading_top' || (this.loading_kind_form.controls['loading_kind'].value && this.loading_kind_form.controls['loading_kind'].value == 'loading_top')) {
      this.loading_kind_form.controls['car'].setValue('with_edscha')
    } else {
      this.loading_kind_form.controls['car'].setValue('sprinter')
    }
    if (($event.value == 'unloading_kind_top' || $event.value == 'unloading_lift') && this.loading_kind_form.controls['loading_kind'].value) {
      this.plan4Possible = true;
      this.plan1Possible = false;
      this.showOverSizeAlert = true;

      this.plan2Possible = false;
      this.plan3Possible = false;
    } else {
      if (this.loading_kind_form.controls['loading_kind'].value) {
        if (this.loading_kind_form.controls['loading_kind'].value == 'loading_kind_side' || this.loading_kind_form.controls['loading_kind'].value == 'loading_kind_top') {
          this.plan4Possible = true;
          this.plan1Possible = false;
          this.plan2Possible = false;
          this.showOverSizeAlert = true;
          this.plan3Possible = false;
        } else {
          this.plan4Possible = false;
          this.plan1Possible = true;
          this.showOverSizeAlert = false;
          this.plan2Possible = true;
          this.plan3Possible = true;
        }
      } else {
        this.plan1Possible = false;
        this.showOverSizeAlert = false;
        this.plan2Possible = false;
        this.plan3Possible = false;
        this.plan4Possible = false;
      }
    }
  }
}
