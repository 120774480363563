import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-download-kravag-thing',
  templateUrl: './download-kravag-thing.component.html',
  styleUrls: ['./download-kravag-thing.component.scss'],
})
export class DownloadKravagThingComponent implements OnInit {

  downloadForm() {
    const pdfUrl = 'assets/kravag/kravag_form.pdf';
    window.open(pdfUrl, '_blank');
  }

  constructor() { }

  ngOnInit() { }


  @Input()
  insuranceDetails!: any;

  @Input()
  orderId!: string;

  @Output()
  kravagDownloaded: EventEmitter<string> = new EventEmitter();

  @Output()
  goBack: EventEmitter<any> = new EventEmitter();

  goToLegal() {
    this.kravagDownloaded.emit('yes')
  }
  goBackToDetails() {
    this.goBack.emit();
  }
}
